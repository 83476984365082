<template>
<form @submit.prevent="" class="technical-edit-form">
    <div class="form-row">
        <div class="col-12 mb-3">
            <input type="text" id="technical-title" v-model="technical.title" class="widget-input input-no-bg input-lg input-set-border w-100">
        </div>
        <div class="col-12">
            <textarea class="widget-textarea textarea-min-h120 textarea-no-bg textarea-no-bg textarea-set-border" id="body" rows="3" v-model="technical.content"></textarea>
        </div>
        <div class="col-12">
            <div class="form-group">
                <div class="form-check form-switch">
                    <label class="form-check-label" for="private">این تحلیل خصوصی می باشد</label>
                    <input class="form-check-input" type="checkbox" id="private" v-model="technical.private">
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <div class="input-group-btn" data-toggle="buttons">
                    <label :class="'btn btn-primary ' + (technical.time_duration == 'shortterm' ? 'active' : '')">
                        <input type="radio" name="time-duration" id="time-duration1" value="shortterm" autocomplete="off" v-model="technical.time_duration">
                        کوتاه
                    </label>
                    <label :class="'btn btn-primary ' + (technical.time_duration == 'midterm' ? 'active' : '')">
                        <input type="radio" name="time-duration" id="time-duration2" value="midterm" autocomplete="off" v-model="technical.time_duration">
                        میان
                    </label>
                    <label :class="'btn btn-primary ' + (technical.time_duration == 'logterm' ? 'active' : '')">
                        <input type="radio" name="time-duration" id="time-duration3" value="logterm" autocomplete="off" v-model="technical.time_duration">
                        بلند
                    </label>
                    <label :class="'btn btn-primary ' + (technical.time_duration == 'none' ? 'active' : '')">
                        <input type="radio" name="time-duration" id="time-duration4" value="none" autocomplete="off" v-model="technical.time_duration">
                        هیچکدام
                    </label>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <div class="input-group-btn" data-toggle="buttons">
                    <label :class="'btn btn-primary ' + (technical.technical_type == 'education' ? 'active' : '')">
                        <input type="radio" name="technical-type" id="technical-type1" value="education" autocomplete="off" v-model="technical.technical_type">
                        آموزشی
                    </label>
                    <label :class="'btn btn-primary ' + (technical.technical_type == 'technical' ? 'active' : '')">
                        <input type="radio" name="technical-type" id="technical-type2" value="technical" autocomplete="off" v-model="technical.technical_type">
                        تحلیلی
                    </label>
                </div>
            </div>
        </div>
        <!-- <div class="col-12">
            <div class="form-group">
                <div class="form-group-file">افزودن فایل</div>
                <label style="display: flex;">
                    <input type="file" name="attachment" id="attachment" accept="image/*" @change="uploadAttachment($event.target.files)" :key="fileInputKey" style="padding-top: 15px;">
                </label>
                <slot v-if="$helpers.isJson(technical.image) && technical.image && $helpers.json_pars(technical.image)[1]">
                    <img :src="$helpers.api_url() + $helpers.json_pars(technical.image)[1].name" style="width:50px;height:50px">
                </slot>
            </div>
        </div> -->
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveChanges()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary rename-technical">
                <i class="uil uil-save"></i>
                ذخیره تغییرات
            </a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت جهت ویرایش تحلیل استفاده میبشود
export default {
    name: 'TechnicalEdit',
    props: ['data'],
    components: {},
    data: function () {
        return {
            technical: {},
            fileInputKey: 0,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true });
    },
    methods: {
        // این متد یک سری داده های اولیه را به کامپوننت تزریق می کند
        loadData() {
            this.technical = this.data.technical;
            this.fileInputKey = Math.random();
        },
        // این متد جهت ارسال درخواست به پرنت برای ذخیره تغییرات تحلیل استفاده می شود
        saveChanges() {
            if (this.technical.title && this.technical.content) {
                this.$parent.emitData('editTechnical', { technical: this.technical }, true);
            }
        },
        // این متد برای انتخاب فایل جهت آپلود استفاده می شود
        uploadAttachment(fileList) {
            if (fileList.length) {
                this.technical.attachment = fileList[0];
            }
        },
    },
}
</script>
